import { FC } from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import Image from "next/legacy/image";
import HorzPill1 from "@/assets/horz-pill-1-new.png";
import HorzPill2 from "@/assets/horz-pill-2-new.png";
import HorzPill3 from "@/assets/horz-pill-3-new.png";
import HorzPill4 from "@/assets/horz-pill-4-new.png";
import VertPill1 from "@/assets/vert-pill-1-new.png";
import VertPill2 from "@/assets/vert-pill-2-new.png";
import VertPill3 from "@/assets/vert-pill-3-new.png";
import VertPill4 from "@/assets/vert-pill-4-new.png";

export const LandingPagePillsSection: FC = () => {
  const mobileScreen = useMediaQuery("(max-width:600px)", {
    noSsr: true,
  });
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    // paddingLeft: { xs: "25px" },
    // paddingRight: { xs: "25px" },
  };

  const iconLayout = {
    marginLeft: { sm: "25px" },
    marginRight: { sm: "25px" },
  };
  const titleText = {
    fontSize: "32px",
    fontWeight: "900",
    lineHeight: "34px",
    marginTop: { xs: "83px", sm: "114px" },
    marginBottom: { xs: "30px", sm: "40px" },
    textAlign: { xs: "left", sm: "center" },
    color: "#163D23",
  };

  return (
    <>
      <Container data-testid="landing-page-pills-section" maxWidth="md" sx={containerStyle}>
        <Typography data-testid="landing-page-pills-section-title-text" sx={titleText}>
          How over-the-counter hearing care works:
        </Typography>
      </Container>

      {mobileScreen ? (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
          <Image
            data-testid="horz-pill-1-image"
            src={HorzPill1}
            width={354}
            height={223}
            alt="Determine if OTC hearing aids work for you"
          />
          <Box mt={1} />
          <Image
            data-testid="horz-pill-2-image"
            src={HorzPill2}
            width={354}
            height={223}
            alt="Browse and order OTC aids from our online store"
          />
          <Box mt={1} />
          <Image data-testid="horz-pill-3-image" src={HorzPill3} width={354} height={223} alt="Receive hearing aids in the mail" />
          <Box mt={1} />
          <Image
            data-testid="horz-pill-4-image"
            src={HorzPill4}
            width={354}
            height={223}
            alt="Pair with smartphone and self-fit to your loss profile"
          />
        </Box>
      ) : (
        <Container maxWidth="md" sx={containerStyle}>
          <Grid container spacing={1} sx={iconLayout}>
            <Grid item xs={3}>
              <Image
                data-testid="vert-pill-1-image"
                src={VertPill1}
                width={194}
                height={526}
                alt="Determine if OTC hearing aids work for you"
              />
            </Grid>
            <Grid item xs={3}>
              <Image
                data-testid="vert-pill-2-image"
                src={VertPill2}
                width={194}
                height={526}
                alt="Browse and order OTC aids from our online store"
              />
            </Grid>
            <Grid item xs={3}>
              <Image data-testid="vert-pill-3-image" src={VertPill3} width={194} height={526} alt="Receive hearing aids in the mail" />
            </Grid>
            <Grid item xs={3}>
              <Image
                data-testid="vert-pill-4-image"
                src={VertPill4}
                width={194}
                height={526}
                alt="Pair with smartphone and self-fit to your loss profile"
              />
            </Grid>
          </Grid>
        </Container>
      )}
      <Box sx={{ marginBottom: 12 }} />
    </>
  );
};
