import React, { FC } from "react";
import { Layout } from "@/lib/components/layout";
import { ComparisonSection } from "@/features/landing-page/comparison-section";
import { QuizSection } from "@/features/landing-page/quiz-section";
import { ConsultationSection } from "@/features/landing-page/consultation-section";
import { ResourceSection } from "@/features/landing-page/resource-section";
import { LandingPagePillsSection } from "@/features/landing-page/landing-page-pills-section";
import { LandingPageBanner } from "@/features/landing-page/landing-page-banner";

export const LandingPageRoute: FC = () => {
  return (
    <Layout>
      <LandingPageBanner />
      <QuizSection landingPage />
      <LandingPagePillsSection />
      <ComparisonSection />
      <ConsultationSection landingPage />
      <ResourceSection landingPage />
    </Layout>
  );
};
