import { FC } from "react";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import Image from "next/legacy/image";
import img1 from "@/assets/happy-family-desktop.png";
import img2 from "@/assets/happy-family-mobile-new.png";

export const LandingPageBanner: FC = () => {
  const mediumScreen = useMediaQuery("(max-width:600px)", {
    noSsr: true,
  });
  const titleText = {
    fontSize: "42px",
    fontWeight: { xs: "900", sm: "900" },
    lineHeight: { xs: "43px", sm: "53px" },
    paddingTop: { xs: "50px", sm: "58px" },
    paddingLeft: { xs: "25px", sm: "0px" },
    paddingRight: { xs: "25px", sm: "auto" },
    paddingBottom: { xs: "25px" },
    color: "#163D23",
    maxWidth: { xs: "350px", sm: "400px" },
  };
  const descriptionBox = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // paddingTop: { md: "50px" },
    paddingLeft: { xs: "25px", sm: "0px" },
    paddingRight: { xs: "25px", sm: "auto" },
    paddingBottom: { xs: "42px", sm: "auto" },
  };

  const descriptionText = {
    fontSize: { xs: "16px", sm: "20px" },
    fontWeight: { xs: "400", sm: "400" },
    lineHeight: { xs: "24px", sm: "28px" },
    maxWidth: { sm: "405px" },
    color: "#313133",
    marginBottom: { xs: "69px", sm: "0px" },
  };
  const imageStyle = {
    display: "flex",
    position: "relative",
    justifyContent: { xs: "flex-end", sm: "flex-start" },
    marginTop: { xs: "0px", sm: "50px" },
    marginRight: { xs: "0px", sm: "0px" },
    marginBottom: { xs: "27px" },
  };
  return (
    <Grid data-testid="banner" sx={{ backgroundColor: "#DEF5E8", overflow: "hidden" }}>
      <Container sx={{ padding: "0px" }}>
        <Grid container>
          <Grid item xs={12} sx={{ display: { xs: "block", sm: "none" } }}>
            <Typography data-testid="banner-title-mobile" sx={titleText}>
              What are OTC hearing aids?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} order={{ xs: 1, sm: 2 }} sx={imageStyle}>
            {mediumScreen ? (
              <Image data-testid={"mediumImage"} src={img2} objectFit="contain" height="359" width="456"></Image>
            ) : (
              <Image data-testid={"desktopImage"} src={img1} layout="fill" objectFit="contain"></Image>
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={descriptionBox} order={{ xs: 2, sm: 1 }}>
            <Typography data-testid="banner-title" sx={{ ...titleText, display: { xs: "none", sm: "block" } }}>
              What are OTC hearing aids?
            </Typography>
            <Typography data-testid="banner-description" sx={descriptionText}>
              New for fall 2022, the FDA has allowed for the sale of hearing aids over the counter (OTC) as an alternative to custom-fit
              prescription hearing aids. OTC hearing aids are a self-serve alternative to hearing care through a licensed hearing
              professional. OTC hearing aids are for individuals age 18 or older who believe they have mild to moderate hearing loss and are
              comfortable with technology.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};
