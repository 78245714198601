import { FC } from "react";
import { GetStaticProps } from "next";
// import { echoAxios } from "@/lib/axios";
import { LandingPageIndex } from "@/lib/api/landing-page";
import { LandingPageRoute } from "@/features/landing-page/landing-page";

type LandingPageProp = {
  landingPage: LandingPageIndex;
};

const LandingPage: FC<LandingPageProp> = () => {
  return <LandingPageRoute />;
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  };
};

export default LandingPage;
